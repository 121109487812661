import React, { useRef } from "react";
import { useIsomorphicLayoutEffect } from "react-use";
import { createPortal } from "react-dom";

const Portal = ({ children }) => {
  const ref = useRef();

  useIsomorphicLayoutEffect(() => {
    const portalRoot = document.getElementById("app-portal");
    const el = document.createElement("div");

    if (portalRoot && !ref.current) {
      portalRoot.appendChild(el);
      ref.current = el;
    }

    return () => portalRoot.removeChild(el);
  }, []);

  return ref.current ? (
    createPortal(children, ref.current, "app-portal")
  ) : (
    <></>
  );
};

export default Portal;
