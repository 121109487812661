import React, { useState } from "react";
import _get from "lodash.get";
import { AnimatePresence, motion } from "framer-motion";
import { useLockBodyScroll } from "react-use";
import { useSwipeable } from "react-swipeable";

import Header from "app/components/Header";
import Pager from "app/components/Pager";
import Portal from "app/components/Portal";

import * as S from "app/components/GalleryModalList.style";

const modalWindow = {
  visible: {
    opacity: 1,
    transition: { duration: 0.3, when: "beforeChildren" },
  },
  hidden: {
    opacity: 0,
    transition: { duration: 0.3, when: "afterChildren" },
  },
};

const modalHeader = {
  visible: {
    y: 0,
  },
  hidden: {
    y: -200,
  },
};

const modalPager = {
  visible: {
    y: 0,
  },
  hidden: {
    y: 200,
  },
};

const modalContent = {
  visible: {
    x: 0,
  },
  hidden: {
    x: 500,
  },
};

const Animated = ({ id, className, variants, children }) => (
  <motion.div
    key={id}
    className={className}
    variants={variants}
    initial={"hidden"}
    animate={"visible"}
    exit={"hidden"}
  >
    {children}
  </motion.div>
);

const GalleryModalList = ({
  gallery,
  List,
  label,
  ModalContent,
  ModalImage,
}) => {
  const [activeId, setActiveId] = useState();
  const items = Array.isArray(gallery) ? gallery : [];
  const idx = items.findIndex(({ id }) => id === activeId);
  const active = items[idx];

  useLockBodyScroll(!!active);

  const pad = items.length > 99 ? 3 : 2;
  const changeItem = (id) => (id ? () => setActiveId(id) : undefined);
  const goNext = changeItem(_get(items, `${[idx + 1]}.id`));
  const goPrev = changeItem(_get(items, `${[idx - 1]}.id`));

  const handlers = useSwipeable({
    onSwipedLeft: goNext,
    onSwipedRight: goPrev,
  });

  return (
    <>
      {React.isValidElement(List) &&
        React.cloneElement(List, {
          images: items,
          setActiveId: setActiveId,
        })}
      <Portal>
        <AnimatePresence>
          {!!active && (
            <Animated
              id={`modal-gallery-item`}
              css={S.modalWindow}
              variants={modalWindow}
            >
              <Header
                onBack={() => setActiveId(undefined)}
                label={label}
                variants={modalHeader}
                key="gallery-header"
                initial={"hidden"}
                animate={"visible"}
                exit={"hidden"}
                transition={{ ease: "linear", duration: 0.4 }}
              />
              <div
                css={[S.container, S.mxauto, S.galleryItemRoot]}
                {...handlers}
              >
                <div css={[S.container, S.mxauto, S.imageArea]}>
                  <div css={[S.imageBox, S.flexCenterAll, S.relative]}>
                    {React.isValidElement(ModalImage) &&
                      React.cloneElement(ModalImage, {
                        ...active,
                        css: [S.flexCenterAll, S.image],
                      })}
                    <Animated
                      css={S.pagerBox}
                      id="gallery-pager"
                      variants={modalPager}
                    >
                      <Pager vert={false} onNext={goNext} onPrev={goPrev} />
                    </Animated>
                  </div>
                </div>
                <Animated
                  id="gallery-content"
                  css={[
                    S.contentArea,
                    S.flex,
                    S.flexCol,
                    S.justifyCenter,
                    S.w100,
                  ]}
                  variants={modalContent}
                >
                  {React.isValidElement(ModalContent) &&
                    React.cloneElement(ModalContent, {
                      ...active,
                      count: `${idx + 1}`.padStart(pad, "0"),
                      countTotal: `${items.length}`.padStart(pad, "0"),
                      idx,
                      total: items.length,
                    })}
                </Animated>
              </div>
            </Animated>
          )}
        </AnimatePresence>
      </Portal>
    </>
  );
};

export default GalleryModalList;
